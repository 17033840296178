<template>
    <app-wrapper>
        <template v-slot:child>
            <div class="w-90 center mt4">
                <div>
                    <div class="b pv3"><router-link :to="{ name: 'PnLBudget' }">&lt; Back to Budget</router-link></div>
                    <div class="b font24">Maintenance of Truck</div>
                </div>
                <div class="flex flex-wrap gap8 justify-between pv4">
                    <div class="budgetViewCard w-30-l w-100">
                        <p class="viewCardHeader pb2">Total Budget</p>
                        <p class="b font16">NGN 100,000</p>
                    </div>
                    <div class="budgetViewCard w-30-l w-100">
                        <p class="viewCardHeader pb2">Actual Budget</p>
                        <p class="b font16">NGN 100,000</p>
                    </div>
                    <div class="budgetViewCard w-30-l w-100">
                        <p class="viewCardHeader pb2">Approved Budget</p>
                        <p class="b font16">NGN 100,000</p>
                    </div>
                </div>
                <div class="budgetViewCard">
                    <div class="flex flex-wrap justify-between">
                        <div>
                            <p class="viewCardHeader pb2">Total Budget</p>
                            <p class="b font16">NGN 100,000</p>
                        </div>
                        <div class="flex flex-wrap justify-between">
                            <div class="w-30">
                                <label for="plStartDate" class="viewCardHeader">Start Date</label>
                                <input
                                    type="date"
                                    name="plStartDate"
                                    class="input-style card-box mb3 mb0-l"
                                    placeholder="Search..."
                                    style="padding: 13px; width: 100%"
                                />
                            </div>
                            <div class="w-30">
                                <label for="plEndDate" class="viewCardHeader">End Date</label>
                                <input
                                    type="date"
                                    name="plEndDate"
                                    class="input-style card-box mb3 mb0-l"
                                    placeholder="Search..."
                                    style="padding: 13px; width: 100%"
                                />
                            </div>
                            <div class="w-30">
                                <label for="lineMonthly" class="viewCardHeader">Month</label>
                                <select name="lineMonthly" id="lineMonthly" style="padding: 13px; width: 100%">
                                    <option value="select">Select Month</option>
                                    <option value="January">January</option>
                                    <option value="February">February</option>
                                    <option value="March">March</option>
                                    <option value="April">April</option>
                                    <option value="May">May</option>
                                    <option value="June">June</option>
                                    <option value="July">July</option>
                                    <option value="August">August</option>
                                    <option value="September">September</option>
                                    <option value="October">October</option>
                                    <option value="November">November</option>
                                    <option value="December">December</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="pt3">
                        <img :src="require('@/assets/images/LineChart.svg')" />
                    </div>
                </div>
                <div class="margin24 pt4">
                    <div class="overflow-auto">
                        <table class="f6 w-100" cellspacing="0">
                            <thead>
                                <tr>
                                    <th class="py-8 billPad manageHeader pl2 fw6 tl p9 pr3 w-25">Line</th>
                                    <th class="py-8 billPad manageHeader pl3 fw6 tl p9 pr3 w-25">Budget</th>
                                    <th class="py-8 billPad manageHeader pl3 fw6 tl p9 pr3 w-25">Actual</th>
                                    <th class="py-8 billPad manageHeader pl3 fw6 tl p9 pr3 w-25">% Achieved</th>
                                </tr>
                            </thead>
                            <tbody class="lh-copy">
                                <tr class="font-w1 box-border-bottom">
                                    <td
                                        @click="toggleSubTable('plAsset')"
                                        class="pv3 bb b--black-20 tl font-w1 flex items-center"
                                        style="padding-right: 4rem; cursor: pointer"
                                    >
                                        <img
                                            id=""
                                            class="showLayers"
                                            :class="plAsset ? 'rotate' : ''"
                                            :src="require('@/assets/images/arrow.png')"
                                            width="5"
                                        />
                                        <div class="ml2 text-capitalize" style="font-size: 13px">Assets</div>
                                    </td>
                                    <td class="pv3 pl3 pr4 bb b--black-20 tl items-center">0.00</td>
                                    <td class="pv3 pl3 pr4 bb b--black-20 tl items-center">0.00</td>
                                    <td class="pv3 pl3 pr4 bb b--black-20 tl items-center">40%</td>
                                </tr>
                                <tr v-if="plAsset" style="padding-top: 5px; padding-bottom: 25px">
                                    <td
                                        class="pl3 bb b--black-20 font13 text-capitalize"
                                        style="padding-top: 15px; padding-bottom: 15px"
                                    >
                                        Equipment
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                </tr>
                                <tr v-if="plAsset" style="padding-top: 5px; padding-bottom: 25px">
                                    <td
                                        class="pl3 bb b--black-20 font13 text-capitalize"
                                        style="padding-top: 15px; padding-bottom: 15px"
                                    >
                                        Trade Payable
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                </tr>
                                <tr v-if="plAsset" style="padding-top: 5px; padding-bottom: 25px">
                                    <td
                                        class="pl3 bb b--black-20 font13 text-capitalize"
                                        style="padding-top: 15px; padding-bottom: 15px"
                                    >
                                        Accrued Expenses
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                </tr>
                                <tr v-if="plAsset" style="padding-top: 5px; padding-bottom: 25px">
                                    <td
                                        class="pl3 bb b--black-20 font13 text-capitalize"
                                        style="padding-top: 15px; padding-bottom: 15px"
                                    >
                                        Income Tax Payable
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                </tr>
                                <tr v-if="plAsset" style="padding-top: 5px; padding-bottom: 25px">
                                    <td
                                        class="pl3 bb b--black-20 font13 text-capitalize"
                                        style="padding-top: 15px; padding-bottom: 15px"
                                    >
                                        Borrowings
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                </tr>

                                <tr class="font-w1 box-border-bottom">
                                    <td
                                        @click="toggleSubTable('plLiability')"
                                        class="pv3 bb b--black-20 tl font-w1 flex items-center"
                                        style="padding-right: 4rem; cursor: pointer"
                                    >
                                        <img
                                            id=""
                                            class="showLayers"
                                            :class="plLiability ? 'rotate' : ''"
                                            :src="require('@/assets/images/arrow.png')"
                                            width="5"
                                        />
                                        <div class="ml2 text-capitalize" style="font-size: 13px">Liability</div>
                                    </td>
                                    <td class="pv3 pl3 pr4 bb b--black-20 tl items-center">0.00</td>
                                    <td class="pv3 pl3 pr4 bb b--black-20 tl items-center">0.00</td>
                                    <td class="pv3 pl3 pr4 bb b--black-20 tl items-center">40%</td>
                                </tr>
                                <tr v-if="plLiability" style="padding-top: 5px; padding-bottom: 25px">
                                    <td
                                        class="pl3 bb b--black-20 font13 text-capitalize"
                                        style="padding-top: 15px; padding-bottom: 15px"
                                    >
                                        Cash on hand
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                </tr>

                                <tr class="font-w1 box-border-bottom">
                                    <td
                                        @click="toggleSubTable('plIncome')"
                                        class="pv3 bb b--black-20 tl font-w1 flex items-center"
                                        style="padding-right: 4rem; cursor: pointer"
                                    >
                                        <img
                                            id=""
                                            class="showLayers"
                                            :class="plIncome ? 'rotate' : ''"
                                            :src="require('@/assets/images/arrow.png')"
                                            width="5"
                                        />
                                        <div class="ml2 text-capitalize" style="font-size: 13px">Income</div>
                                    </td>
                                    <td class="pv3 pl3 pr4 bb b--black-20 tl items-center">0.00</td>
                                    <td class="pv3 pl3 pr4 bb b--black-20 tl items-center">0.00</td>
                                    <td class="pv3 pl3 pr4 bb b--black-20 tl items-center">40%</td>
                                </tr>
                                <tr v-if="plIncome" style="padding-top: 5px; padding-bottom: 25px">
                                    <td
                                        class="pl3 bb b--black-20 font13 text-capitalize"
                                        style="padding-top: 15px; padding-bottom: 15px"
                                    >
                                        Cash on hand
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                </tr>

                                <tr class="font-w1 box-border-bottom">
                                    <td
                                        @click="toggleSubTable('plExpenses')"
                                        class="pv3 bb b--black-20 tl font-w1 flex items-center"
                                        style="padding-right: 4rem; cursor: pointer"
                                    >
                                        <img
                                            id=""
                                            class="showLayers"
                                            :class="plExpenses ? 'rotate' : ''"
                                            :src="require('@/assets/images/arrow.png')"
                                            width="5"
                                        />
                                        <div class="ml2 text-capitalize" style="font-size: 13px">Expenses</div>
                                    </td>
                                    <td class="pv3 pl3 pr4 bb b--black-20 tl items-center">0.00</td>
                                    <td class="pv3 pl3 pr4 bb b--black-20 tl items-center">0.00</td>
                                    <td class="pv3 pl3 pr4 bb b--black-20 tl items-center">40%</td>
                                </tr>
                                <tr v-if="plExpenses" style="padding-top: 5px; padding-bottom: 25px">
                                    <td
                                        class="pl3 bb b--black-20 font13 text-capitalize"
                                        style="padding-top: 15px; padding-bottom: 15px"
                                    >
                                        Cash on hand
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                </tr>

                                <tr class="font-w1 box-border-bottom">
                                    <td
                                        @click="toggleSubTable('plEquity')"
                                        class="pv3 bb b--black-20 tl font-w1 flex items-center"
                                        style="padding-right: 4rem; cursor: pointer"
                                    >
                                        <img
                                            id=""
                                            class="showLayers"
                                            :class="plEquity ? 'rotate' : ''"
                                            :src="require('@/assets/images/arrow.png')"
                                            width="5"
                                        />
                                        <div class="ml2 text-capitalize" style="font-size: 13px">Equity</div>
                                    </td>
                                    <td class="pv3 pl3 pr4 bb b--black-20 tl items-center">0.00</td>
                                    <td class="pv3 pl3 pr4 bb b--black-20 tl items-center">0.00</td>
                                    <td class="pv3 pl3 pr4 bb b--black-20 tl items-center">40%</td>
                                </tr>
                                <tr v-if="plEquity" style="padding-top: 5px; padding-bottom: 25px">
                                    <td
                                        class="pl3 bb b--black-20 font13 text-capitalize"
                                        style="padding-top: 15px; padding-bottom: 15px"
                                    >
                                        Cash on hand
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="pt4 flex" style="justify-content: flex-end">
                            <div class="flex gap8">
                                <button class="btn2">Cancel</button>
                                <button class="btn1">Print</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>
<script>
import { ref } from 'vue'
import AppWrapper from '@/layout/AppWrapper.vue'
export default {
    name: 'PnLView',
    components: { AppWrapper },
    setup() {
        const plAsset = ref(false)
        const plLiability = ref(false)
        const plIncome = ref(false)
        const plExpenses = ref(false)
        const plEquity = ref(false)

        const toggleSubTable = (name) => {
            if (name === 'plAsset') {
                plAsset.value = !plAsset.value
            }
            if (name === 'plLiability') {
                plLiability.value = !plLiability.value
            }
            if (name === 'plIncome') {
                plIncome.value = !plIncome.value
            }
            if (name === 'plExpenses') {
                plExpenses.value = !plExpenses.value
            }
            if (name === 'plEquity') {
                plEquity.value = !plEquity.value
            }
        }
        return {
            plAsset,
            plLiability,
            plIncome,
            plExpenses,
            plEquity,
            toggleSubTable,
        }
    },
}
</script>
<style scoped>
.font24 {
    font-size: 24px;
}
.font16 {
    font-size: 16px;
}
.budgetViewCard {
    border-radius: 8px;
    border: 1px solid #d0d9ff;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    padding: 20px;
}
.viewCardHeader {
    color: rgba(85, 89, 110, 0.75);
}
.w-25 {
    width: 25%;
}
.rotate {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
</style>
